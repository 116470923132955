
<template>
  <div class='selected'>
    <!-- <div class="text">已选座位:</div> -->
    <div class="scroll" ref="scroll">
      <ul class="scroll-ul" ref="scrollUl">
        <li class="scroll-item" v-for="item in selectedSeat" :key="'select' + item.seatId" @click="cancelSelect(item)">
          <div class="scroll-item-t">
            <div :xt="'rowNo:' + item.rowNo + ',columnNo:' + item.columnNo" class="f26 c3">{{ item.seatNo }}</div>
            <div class="sel-y">{{ $utils.getPricePoint(item.price) }}点</div>
          </div>
          <div class="scroll-item-del">
            <img class="deleteimage"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAQCAYAAADwMZRfAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3FpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpiNGJhYTA3ZS0zNmRiLWNjNDYtOGE2Yi1kZjdmZWU3Nzk4MDUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6REZBQTdDOUFBRkNDMTFFQ0I5ODc4NjQwMUVDMEU5MUQiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6REZBQTdDOTlBRkNDMTFFQ0I5ODc4NjQwMUVDMEU5MUQiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjhkMGE5YTBhLTgwZDctNDg0OS04NDUwLTljNWJkMmU0NjFjYSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpiNGJhYTA3ZS0zNmRiLWNjNDYtOGE2Yi1kZjdmZWU3Nzk4MDUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5Gjq8EAAAAv0lEQVR42mKcOXMmAxAYAPEGIA4A4gsMhEECEBcAsQMQf2CCGnAAiOWhtAERBswHYn2oegGQIQpAzA9VwE/AIJgBMKAAMwTkjUQkCVwGoRvwEeqdB0xQgQUEDMJlADj8mJAkcBnUgM8AdENwGVSPzwBshmAzCK8BuAwhGTAREY2EYg3DEGyx0EjIICYi0kEDoXTEREw6IJSOmKCZbj4RsYDTICYo4yKhaMRh0ANYsv8A1XiQgAHoBl2EFQUAAQYAE6BBq2OSiW0AAAAASUVORK5CYII="
              alt="" srcset="">
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Bscroll from 'better-scroll'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    propSelectedSeat: Array
  },
  data() {
    // 这里存放数据
    return {
      selectedSeat: this.propSelectedSeat
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    propSelectedSeat: function (value) {
      let width = this.propSelectedSeat.length * 2.67 + 1.2
      this.$refs.scrollUl.style.width = width + 'rem'
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new Bscroll(this.$refs.scroll, {
            scrollX: true,
            // 忽略竖直方向的滚动
            scrollY: false,
            eventPassthrough: 'vertical'
          })
        } else {
          this.scroll.refresh()
        }
      })
    }
  },
  // 方法集合
  methods: {
    cancelSelect: function (item) {
      this.$emit('cancelSelect', item.orgIndex)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped='scoped'>
.selected {
  position: fixed;
  z-index: 10;
  bottom: 2rem;
  background: white;
  width: 100%; // padding 0 20px 0 20px
  height: 6.5rem;
  overflow: hidden;
}

// border-bottom: 2px dashed #ccc
.text {
  width: 100%;
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 1.2rem;
  font-weight: 400;
}

.scroll {
  position: absolute; // height 140px
  width: 100%;

  left: 0;
  z-index: 11;
}

.scroll-ul {
  width: 100%;
  display: flex;
  padding-left: 0.3rem;
}


.scroll-item {
  flex: none;
  margin-left: 0.5rem;
  height: 3.2rem;
  font-size: 0.8rem;
  width: 5.3rem;
  color: #888888;
  display: flex;
  -webkit-justify-content: space-around;
  align-items: center;
  -webkit-align-items: center;
  background: #f6f6f6;
}

.scroll-item-t {
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  font-size: 0.8rem;

}

.sel-y {
  color: #f00;
  margin-top: 0.2rem
}

.scroll-item-del {}

.deleteimage {
  width: 0.5rem
}

.f26 {
  font-size: 0.8rem
}

.c3 {
  color: #666
}
</style>
