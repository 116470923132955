
<template>
  <div>
    <div class="sea-detail">
      <slot></slot>
    </div>
  </div>
</template>

<script>


export default {
  props: {
    propHallName: String,
    propShowDate: String,
    propShowTime: String
  },
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {

    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {

  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped='scoped'>
 
.plan-detail{

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 26px;
  color: #888888;
  height: 90px;
  width: 100%;
  background: #ffffff;
}
  
  .plan-detail-item{
    width: 2rem;
    text-align: center;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
   
.sea-detail{
  display :flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: 1rem;
  color: #888888;
  height: 2rem;
  width: 100%;
  background :#f3f4f6;
  
}

</style>
